import React from 'react';
import { useDispatch } from 'react-redux';

import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';

import DistanceSlider from './DistanceSlider';
import Geocoder from './Geocoder';
import ContextChooser from './ContextChooser';
import ServiceChooser from './ServiceChooser';
import FacilityCount from './FacilityCount';
import Partnerships from './Partnerships';
import ProviderUpdate from './ProviderUpdate';

import { openModal } from '../../reducers/modal';
import { modalTypes } from '../Modal/Modal';


const useStyles = makeStyles({
  howTo: {
    marginTop: 20,
    marginLeft: 45,
  },
  disclaimer: {
    marginLeft: 90,
  },
});

const SidebarQuery = () => {
  const dispatch = useDispatch();

  const showHowTo = () => {
    dispatch(openModal({ type: modalTypes.HOW_TO_MODAL }));
  };

  const showDisclaimer = () => {
    dispatch(openModal({ type: modalTypes.DISCLAIMER_MODAL }));
  };

  const classes = useStyles();

  return (
    <>
      <Geocoder />
      <DistanceSlider />
      <ContextChooser />
      <ServiceChooser />
      <FacilityCount />
      <Button className={classes.howTo} onClick={showHowTo} color="primary">
        How to use this finder
      </Button><br />
      <Button className={classes.disclaimer} onClick={showDisclaimer} color="primary">
        Disclaimer
      </Button>
      <Partnerships />
      <ProviderUpdate />
    </>
  );
};

export default SidebarQuery;
