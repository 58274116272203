import config from '../../config';

const { google: { mapOptions } } = config;

export default (zoom = mapOptions.zoom, lat = mapOptions.center.lat, lng = mapOptions.center.lng, satellite) => ({
  center: { lat: Number(lat), lng: Number(lng) },
  zoom: Number(zoom),
  disableDefaultUI: true,
  mapTypeId: satellite ? window.google.maps.MapTypeId.SATELLITE : window.google.maps.MapTypeId.ROADMAP,
  streetViewControl: true,
  rotateControl: true,
  rotateControlOptions: {
    position: window.google.maps.ControlPosition.RIGHT_BOTTOM,
  },
  gestureHandling: 'greedy',
  clickableIcons: false,
  styles: [
    {
      featureType: 'poi',
      elementType: 'labels',
      stylers: [{ visibility: 'off' }],
    },
    {
      featureType: 'poi.park',
      elementType: 'labels',
      stylers: [{ visibility: 'on' }],
    },
    {
      featureType: 'transit',
      elementType: 'labels.icon',
      stylers: [{ visibility: 'on' }],
    },
    {
      featureType: 'road.arterial',
      elementType: 'labels.icon',
      stylers: [{ visibility: 'on' }],
    },
    {
      featureType: 'road.highway',
      elementType: 'labels.icon',
      stylers: [{ visibility: 'on' }],
    },
    {
      featureType: 'road.highway.controlled_access',
      elementType: 'labels.icon',
      stylers: [{ visibility: 'off' }],
    },
    {
      featureType: 'landscape.man_made',
      elementType: 'labels',
      stylers: [{ visibility: 'off' }],
    },
  ],
});
