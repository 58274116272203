import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import WifiTetheringIcon from '@material-ui/icons/WifiTethering';
import EmergencyNotice from './EmergencyNotice';

const useStyles = makeStyles({
  nameLink: {
    textDecoration: 'none',
    color: '#2f788f',
    fontWeight: 'bold',
  },
  close: {
    marginRight: 15,
  },
  iconDef: {
    verticalAlign: 'middle',
    marginLeft: 60,
    textIndent: -30,
  },
  icon: {
    fontFamily: 'Material Icons',
    fontSize: 24,
    verticalAlign: 'middle',
  },
});


const HowToModal = ({ handleClose }) => {
  const classes = useStyles();

  return (
    <>
      <DialogTitle id="responsive-dialog-title">How to use this finder</DialogTitle>
      <DialogContent>
        <DialogContentText>
          <EmergencyNotice />
          <p>To search for health services, enter an address under My Location or click the Use Current Device
            Location button (<span className={classes.icon}>&#xe55c;</span>) on the left side panel.  You can change
            the Distance to Search in Miles, Type of Visit, or Service as necessary.  The map view will show the locations
            of the service providers matching your search criteria.  Switch to a list view of the service providers
            by clicking on the List button.  The left side panel can be dismissed by clicking on the hamburger
            button (<span className={classes.icon}>&#xe5d2;</span>).
          </p>
          <p>Click on a service location in the map view or on a More Information button (<span className={classes.icon}>&#xe88e;</span>)
            in the list view to see details about that service provider.
          </p>
          <p>A Telehealth Access Point (TAP) is a dedicated public space for individuals to access a telehealth appointment. These spaces consist of an adequate internet connection, a device with working camera, speaker and microphone, as well as privacy considerations in the form of a dedicated room or kiosk where the telehealth visit can be conducted. TAPs may also have support staff to assist an individual through a telehealth appointment if needed. By enabling this feature,
            (<span className={classes.icon}><WifiTetheringIcon /></span>) you can see where TAPs are located in the United States. If you know of a TAP in your area that is not on this map, please submit location information using this <a href="https://wimtracking.com/taps" className={classes.nameLink} rel="noreferrer" target="_blank">form</a>.
          </p>
          <p>TAPs locations can update their information here: <a href="https://wimtracking.com/taps" className={classes.nameLink} rel="noreferrer" target="_blank">form</a></p>
          <p>Symbols shown on the map:
            <dl>
              <dd className={classes.iconDef}><span className={classes.icon}>&#xf080;</span> Telehealth services only at this location</dd>
              <dd className={classes.iconDef}><span className={classes.icon}>&#xe939;</span> In-person services only at this location</dd>
              <dd className={classes.iconDef}><span className={classes.icon}>&#xf051;</span> Both telehealth and in-person services at this location</dd>
              <dd className={classes.iconDef}><span className={classes.icon}><WifiTetheringIcon /></span> Public facing Telehealth Access Point (TAP)</dd>
            </dl>
          </p>
          <p>All distances shown in the list view are approximate and may be significantly less that the true
            travel distance between your address and the service provider.  To get an accurate distance and driving
            directions to a service provider in the list view, click on the Directions button (<span className={classes.icon}>&#xeff7;</span>).
          </p>
          <p>ABOUT THIS FINDER:</p>
          <p>This telehealth finder has been provided by the&nbsp;
            <a href="https://nrtrc.org" className={classes.nameLink} rel="noreferrer" target="_blank">Northwest Regional Telehealth Resource Center (NRTRC)</a>.
            The NRTRC serves a seven-state region (AK, WA, OR, MT, ID, WY, UT) to advance the development,
            implementation and integration of telehealth through sharing information, leveraging resources and
            creating a synergistic telehealth community.  This finder also includes two states (AZ, NV) served by
            the <a href="https://southwesttrc.org/" className={classes.nameLink} rel="noreferrer" target="_blank">Southwest Regional Telehealth Resource Center (SWTRC)</a>.
          </p>
          <p>This project is supported by the Health Resources and Services Administration (HRSA) of the U.S.
            Department of Health and Human Services (HHS) under G22RH31114, GA5RH37461-01-00, and
            6 U1UTH42527-03-01 Telehealth Resource Center’s Grant Program.
          </p>
          <p>For any questions contact the NRTRC at <a href="mailto:info@nrtrc.org" className={classes.nameLink} target="_blank" rel="noreferrer">info@nrtrc.org</a>.
          </p>
          <p>Health service providers can update their information here: <a lassName={classes.nameLink} href="https://wimtracking.com/telehealth/" rel="noreferrer" target="_blank">Telehealth Survey • WIM Tracking</a>
          </p>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button className={classes.close} autoFocus onClick={handleClose} color="primary">
          Close
        </Button>
      </DialogActions>
    </>
  );
};

export default HowToModal;
