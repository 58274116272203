import { takeLatest, put } from 'redux-saga/effects';
import { LOAD_SERVICES, setAllServices } from '../reducers/queryForm';

function* fetchServices() {
  try {
    const data = yield fetch('/service').then((resp) => resp.json());
    yield put(setAllServices(data));
  } catch (e) {
    // TODO: alert user
    console.log('ERROR fetching sample data', e);
  }
}

export default function* watch() {
  yield takeLatest(LOAD_SERVICES, fetchServices);
}
