import React from 'react';
import { connect } from 'react-redux';

import Dialog from '@material-ui/core/Dialog';

import DetailsModal from './DetailsModal';
import DisclaimerModal from './DisclaimerModal';
import HowToModal from './HowToModal';
import TapsModal from './TapsModal';

import { closeModal } from '../../reducers/modal';

import { createDispatchBindings } from '../../utils/redux';

export const modalTypes = {
  DETAILS_MODAL: 'DETAILS_MODAL',
  DISCLAIMER_MODAL: 'DISCLAIMER_MODAL',
  HOW_TO_MODAL: 'HOW_TO_MODAL',
  TAPS_MODAL: 'TAPS_MODAL',
};

const Modal = ({ closeModal, open, type, data, size, fullScreen }) => {
  // hide modal if not open
  if (!open) return '';

  let Content;

  switch (type) {
    case modalTypes.DETAILS_MODAL:
      Content = DetailsModal;
      break;
    case modalTypes.DISCLAIMER_MODAL:
      Content = DisclaimerModal;
      break;
    case modalTypes.TAPS_MODAL:
      Content = TapsModal;
      break;
    default:
      Content = HowToModal;
      break;
  }
  return (
    <Dialog
      maxWidth={size}
      open={open}
      fullScreen={fullScreen}
      onClose={closeModal}
      disableBackdropClick
    >
      <Content data={data} handleClose={closeModal} />
    </Dialog>
  );
};

const mapStateToProps = ({ modal }) => ({ ...modal });

const mapDispatchToProps = createDispatchBindings({ closeModal });

export default connect(mapStateToProps, mapDispatchToProps)(Modal);
