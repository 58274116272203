import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  root: {
    width: '100%',
    marginTop: 30,
    paddingLeft: 30,
    paddingRight: 30,
    whiteSpace: 'normal',
    textAlign: 'center',
  },
  link: {
    textDecoration: 'none',
    color: '#3f51b5',
    fontWeight: 'bold',
  },
});

export default function ProviderUpdate() {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <p>Health service providers<br />
        <a className={classes.link} href="https://wimtracking.com/telehealth/" rel="noreferrer" target="_blank">Add or Update Information</a>
      </p>
      <p>
        <a className={classes.link} href="https://wimtracking.com/taps/" rel="noreferrer" target="_blank">Add Telehealth Access Point (TAP)</a>
      </p>
    </div>
  );
}
