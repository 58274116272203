import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

import swtrcLogo from '../../assets/swtrc_logo.png';
import azTelemedLogo from '../../assets/az-telemed_logo.png';

const useStyles = makeStyles({
  root: {
    width: '100%',
    marginTop: 30,
    paddingLeft: 30,
    paddingRight: 30,
    whiteSpace: 'normal',
    textAlign: 'center',
  },
  azTelemedLogo: {
    width: 120,
  },
  swtrcLogo: {
    width: 160,
  },
});

export default function Partnerships() {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <p>In partnership with</p>
      <p>
        <a href="https://telemedicine.arizona.edu/" title="Go to the Arizona Telemedicine Program website" rel="noreferrer" target="_blank">
          <img className={classes.azTelemedLogo} src={azTelemedLogo} alt="Arizona Telemedicine Program Logo" />
        </a>
      </p>
      <p>
        <a href="https://southwesttrc.org/" title="Go to the SWTRC website" rel="noreferrer" target="_blank">
          <img className={classes.swtrcLogo} src={swtrcLogo} alt="SWTRC Logo" />
        </a>
      </p>
    </div>
  );
}
