import React, { useEffect } from 'react';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import Tooltip from '@material-ui/core/Tooltip';
import { useDispatch, useSelector } from 'react-redux';
import { getTapSwitchState, loadTaps, setTapsOn } from '../reducers/taps';

const styles = {
  div: {
    width: '124px',
    backgroundColor: 'white',
    borderRadius: '4px',
  },
  label: {
    fontSize: '14px',
    fontWeight: '600',
    paddingRight: '18px',
  },
};
const TapControl = ({ className }) => {
  const tapsOn = useSelector(getTapSwitchState);
  const dispatch = useDispatch();

  const handleChange = () => {
    dispatch(setTapsOn(!tapsOn));
    if (!tapsOn) dispatch(loadTaps());
  };

  useEffect(() => {
    if (tapsOn) dispatch(loadTaps());
  }, []);

  return (
    <Tooltip title="Telehealth Access Points">
      <FormControlLabel
        className={className}
        control={(
          <Switch
            color="secondary"
            checked={tapsOn}
            onChange={handleChange}
            inputProps={{
              'aria-label': 'checkbox for TeleHealth Access Points',
            }}
          />
        )}
        label={<span style={styles.label}>TAPs</span>}
        labelPlacement="start"
        style={styles.div}
      />
    </Tooltip>
  );
};

export default TapControl;
