import { takeLatest, select } from 'redux-saga/effects';
import { getTapSwitchState, SET_TAPS_ON } from '../reducers/taps';
import { removeTapsFromMap } from '../utils/map-google/mapUtils';
// import { updateTaps } from '../utils/map-google/mapUtils';

function* removeTaps() {
  const tapsOn = yield select(getTapSwitchState);
  if (!tapsOn) removeTapsFromMap();
}

export default function* watch() {
  yield takeLatest(SET_TAPS_ON, removeTaps);
}
