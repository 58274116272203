// import React, { useEffect } from 'react';
import React from 'react';
import clsx from 'clsx';
import Button from '@material-ui/core/Button';
import UICard from '@material-ui/core/Card';
import Grid from '@material-ui/core/Grid';
import CardContent from '@material-ui/core/CardContent';

import { makeStyles } from '@material-ui/core/styles';
import { useDispatch } from 'react-redux';

import { openModal } from '../../reducers/modal';
import { modalTypes } from '../Modal/Modal';

const useStyles = makeStyles({
  content: {
    position: 'relative',
  },
  button: {
    position: 'absolute',
    bottom: 10,
    fontFamily: 'Material Icons',
    fontSize: 24,
    width: 30,
    height: 30,
    minWidth: 30,
    minHeight: 30,
    color: '#404040',
  },
  name: {
    fontWeight: 'bold',
  },
  nameLink: {
    textDecoration: 'none',
    color: '#2f788f',
    fontWeight: 'bold',
  },
  phoneLink: {
    display: 'inline-block',
    textDecoration: 'none',
    color: '#2f788f',
    fontSize: 16,
    paddingTop: 4,
    paddingBottom: 4,
  },
  distance: {
    display: 'inline-block',
    paddingTop: 4,
  },
});

const Card = ({ facility, queryForm }) => {
  const classes = useStyles();

  let name = <span className={classes.name}>{ facility.account_name }</span>;

  if (facility.website) {
    name = <a href={facility.website} className={classes.nameLink} rel="noreferrer" target="_blank">{ facility.account_name }</a>;
  }

  let phone = <><br />Click the link above to schedule</>;

  if (facility.phone) {
    const phoneUri = `tel:${facility.phone}`;
    phone = <><br /><a href={phoneUri} className={classes.phoneLink}>{ facility.phone }</a></>;
  }

  let directions = '';
  let location;

  if (facility.telehealth_only) {
    location = <><br />Serving all of { facility.telehealth_states.sort().join(', ') }</>;
  } else {
    const miles = (facility.distance * 1.2) / 1609.36;
    let distance = 'less than 1 mile';

    if (miles >= 0.5) {
      if (miles <= 20) {
        if (miles < 1.5) {
          distance = 'about 1 mile';
        } else {
          distance = `about ${Math.round(miles)} miles`;
        }
      } else {
        distance = `about ${Math.round(miles * 0.2) * 5} miles`;
      }
    }

    if (facility.in_person_services.length) {
      const getDirections = () => {
        const coords = facility.location.coordinates;
        window.open(`https://www.google.com/maps/dir/?api=1&origin=${queryForm.location.lat},${queryForm.location.lng}&destination=${coords[1]},${coords[0]}&travelmode=driving`, '_blank');
      };
      directions = <Button id="directions" title="Directions" className={clsx(classes.button, 'no-print')} style={{ right: 40 }} onClick={getDirections}>&#xeff7;</Button>;
    }

    location = <><br />{ facility.address }<br />{ facility.city }, { facility.state }<br /><span className={classes.distance}>{ distance } away</span></>;
  }

  const dispatch = useDispatch();

  const showDetails = () => {
    dispatch(openModal({ type: modalTypes.DETAILS_MODAL, data: facility }));
  };

  return (
    <Grid className="print-card" item xs={12} sm={6} md={4} lg={3} xl={2}>
      <UICard>
        <CardContent className={classes.content}>
          { directions }
          <Button id="details" title="More information" className={clsx(classes.button, 'no-print')} style={{ right: 10 }} onClick={showDetails}>&#xe88e;</Button>
          { name }
          { phone }
          { location }
        </CardContent>
      </UICard>
    </Grid>
  );
};

export default Card;
