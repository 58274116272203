import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import { setLocation, submitSearch } from '../../reducers/queryForm';

// Simulate a 'down arrow' keypress when the enter key is pressed in the Autocomplete widget
// https://stackoverflow.com/questions/7865446/google-maps-places-api-v3-autocomplete-select-first-option-on-enter
// See "A working answer for 2020"

const enableEnterKey = (input) => {
  const originalAddEventListener = input.addEventListener;

  const addEventListenerWrapper = (type, listener) => {
    let newListener = listener;

    if (type === 'keydown') {
      newListener = (event) => {
        setTimeout(() => {
          const suggestionSelected = document.getElementsByClassName('pac-item-selected').length;

          if (event.key === 'Enter' && !suggestionSelected) {
            const e = new KeyboardEvent('keydown', {
              key: 'ArrowDown',
              code: 'ArrowDown',
              keyCode: 40,
            });

            listener.apply(input, [e]);
          }

          listener.apply(input, [event]);
        }, 250);
      };
    }

    originalAddEventListener.apply(input, [type, newListener]);
  };

  input.addEventListener = addEventListenerWrapper;
};

const getAddressComponent = (place, compName, type = 'long_name') => {
  let value;

  if (place && place.address_components) {
    const component = place.address_components.find((c) => c.types.indexOf(compName) >= 0);

    if (component) {
      value = component[type];
    }
  }

  return value;
};

const getState = (place) => {
  const country = getAddressComponent(place, 'country');

  if (country !== 'United States') {
    return { name: 'Outside United States' };
  }

  return {
    name: getAddressComponent(place, 'administrative_area_level_1'),
    abbrev: getAddressComponent(place, 'administrative_area_level_1', 'short_name'),
  };
};

const useStyles = makeStyles({
  root: {
    width: '100%',
    marginTop: 30,
    paddingLeft: 30,
    paddingRight: 30,
  },
  label: {
    color: '#0e78be',
    fontWeight: 'bold',
    display: 'inline-block',
  },
  button: {
    fontFamily: 'Material Icons',
    fontSize: 24,
    width: 30,
    height: 30,
    minWidth: 30,
    minHeight: 30,
    marginLeft: 6,
    marginTop: -4,
    color: '#404040',
  },
  field: {
    width: 240,
  },
});

export default function Geocoder() {
  const classes = useStyles();

  const mapDiv = useSelector((state) => state.mapRefs.mapDiv);
  const [, setAutocomplete] = useState();
  const dispatch = useDispatch();

  useEffect(() => {
    const element = document.getElementById('geocoder');

    if (!element) {
      return;
    }

    const autocomplete = new window.google.maps.places.Autocomplete(element, {
      fields: ['address_component', 'formatted_address', 'geometry', 'name', 'type', 'vicinity'],
      bounds: {
        north: 72,
        south: 34.5,
        east: -104,
        west: -178,
      },
    });
    autocomplete.addListener('place_changed', () => {
      const place = autocomplete.getPlace();

      if (place && place.geometry) {
        const location = {
          lat: place.geometry.location.lat(),
          lng: place.geometry.location.lng(),
          state: getState(place),
        };
        dispatch(setLocation(location));
        dispatch(submitSearch());
      }
    });

    enableEnterKey(element);
    setAutocomplete(autocomplete);
  }, [mapDiv]);

  useEffect(() => {
    const autocomplete = document.getElementById('geocoder');
    const element = document.getElementById('location');

    if (!element) {
      return;
    }

    const { geolocation } = navigator;

    if (!geolocation) {
      element.style.color = 'gray';
      element.title = 'Current device location - unavailable';
      return;
    }

    element.title = 'Use current device location';

    element.addEventListener('click', () => {
      geolocation.getCurrentPosition(async (p) => {
        const { coords } = p;
        const { latitude: lat, longitude: lng } = coords;
        const location = { lat, lng };

        const geocoder = new window.google.maps.Geocoder();
        const { results } = await geocoder.geocode({ location });

        if (results.length) {
          autocomplete.value = results[0].formatted_address;
          location.state = getState(results[0]);
          dispatch(setLocation(location));
          dispatch(submitSearch());
        }
      },
      () => {
        element.style.color = 'gray';
        element.title = 'Current device location - unavailable';
      });
    });
  }, [mapDiv]);

  if (!mapDiv) {
    return '';
  }

  return (
    <div className={classes.root}>
      <Typography id="geocoder-label" className={classes.label}>
        My location
      </Typography>
      <Button id="location" className={classes.button}>&#xe55c;</Button><br />
      <TextField
        className={classes.field}
        fullWidth
        aria-labelledby="geocoder-label"
        inputProps={{ id: 'geocoder', placeholder: 'Enter an address or place name', style: { fontSize: '0.875rem' } }}
      />
    </div>
  );
}
