import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  text: {
    color: '#E00000',
  },
  link: {
    textDecoration: 'none',
    color: '#A00000',
    fontWeight: 'bold',
  },
}));

export default function EmergencyNotice() {
  const classes = useStyles();

  return (
    <>
      <p className={classes.text}>If you are experiencing shortness of breath, chest pain or a life-threatening emergency please
        call 911.
      </p>
      <p className={classes.text}>In case of suicidal crisis, please reach out to the National Suicide Prevention Lifeline
        at <a href="tel:800-273-8255" className={classes.link}>800-273-8255</a> or&nbsp;
        <a href="https://suicidepreventionlifeline.org" className={classes.link} rel="noreferrer" target="_blank">https://suicidepreventionlifeline.org</a>.
      </p>
    </>
  );
}
