import qs from 'querystring';
import { takeLatest, put, select } from 'redux-saga/effects';
import { setFacilities } from '../reducers/facility';
import { SUBMIT_SEARCH } from '../reducers/queryForm';
import { updateMap } from '../utils/map-google/mapUtils';

import loading from '../utils/loading';

function* fetchFacilities() {
  const loadingMsg = 'Finding facilities...';
  let loaded = false;

  setTimeout(() => {
    if (!loaded) {
      loading.start(loadingMsg);
    }
  }, 1000);

  try {
    const { location, distance, context, selectedServices } = yield select((state) => state.queryForm);

    if (!Number.isNaN(location.lat)) {
      const radius = distance * 1609.36;
      const query = { lat: location.lat, lon: location.lng, distance: radius };

      if (context && context !== 'Both') {
        query.context = context;
      }

      if (selectedServices && selectedServices.length && selectedServices !== 'Any') {
        query.services = selectedServices;
      }

      query.state = location.state.abbrev;

      const data = yield fetch(`/facility?${qs.stringify(query)}`).then((resp) => resp.json());
      yield put(setFacilities(data));
      updateMap(location, radius, data);
    }
  } catch (e) {
    // TODO: alert user
    console.log('ERROR fetching sample data', e);
  } finally {
    loaded = true;
    loading.end(loadingMsg);
  }
}

export default function* watch() {
  yield takeLatest(SUBMIT_SEARCH, fetchFacilities);
}
