// import React, { useEffect } from 'react';
import React from 'react';
import clsx from 'clsx';
import { useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import facility from '../../reducers/facility';
import Card from './Card';
import config from '../../config';

const { headerHeight } = config;

const nrtrcStates = ['Idaho', 'Montana', 'Wyoming', 'Alaska', 'Oregon', 'Utah', 'Washington', 'Nevada', 'Arizona'];

const useStyles = makeStyles(() => ({
  root: {
    marginTop: 65,
    paddingLeft: 15,
    width: '100%',
    height: `calc(100% - ${headerHeight}px - 65px)`,
    overflowX: 'clip',
    overflowY: 'scroll',
  },
  link: {
    textDecoration: 'none',
    color: '#2f788f',
    fontWeight: 'bold',
  },
  grid: {
    width: 'calc(100% - 15px)',
    margin: 0,
  },
}));

const ListPanel = () => {
  const classes = useStyles();
  const facilities = useSelector((state) => state.facility);
  const q = useSelector((state) => state.queryForm);
  let message = 'No facilities found.';

  if (!facilities.length) {
    if (q.location.state && nrtrcStates.indexOf(q.location.state.name) < 0) {
      if (q.location.state.name !== 'Outside United States') {
        message = 'At this time we do not have data for telehealth or healthcare facility information for states outside of the NRTRC and SWTRC regions.';
      } else {
        message = 'This finder is not meant for use outside of the United States.';
      }
    }

    return (
      <div className={classes.root}>
        <Typography>
          <p>{message}</p>
          <p>For any questions contact the NRTRC at <a href="mailto:info@nrtrc.org" className={classes.link} target="_blank" rel="noreferrer">info@nrtrc.org</a>.</p>
        </Typography>
      </div>
    );
  }

  if (q.context === 'In-person') {
    message = 'This list only includes in-person healthcare service provider facilities located in the specified search area.';
  } else {
    message = 'This list may include telehealth providers that are physically located outside of the specified search area.';
  }

  return (
    <div className={clsx(classes.root, 'print-list')}>
      <Typography>
        <p>{message}</p>
      </Typography>
      <Grid className={clsx(classes.grid, 'print-list-container')} container spacing={2}>
        {
          facilities.map((f) => <Card facility={f} queryForm={q} key={facility.account_id} />)
        }
      </Grid>
    </div>
  );
};


export default ListPanel;
