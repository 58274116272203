import { createMuiTheme } from '@material-ui/core/styles';

export const theme = createMuiTheme({
  overrides: {
    MuiSwitch: {
      switchBase: {
        // Controls default (unchecked) color for the thumb
        color: '#d1d1d1',
      },
      colorSecondary: {
        '&$checked': {
          // Controls checked color for the thumb
          color: '#ff6600',
        },
      },
      track: {
        // Controls default (unchecked) color for the track
        opacity: 0.2,
        backgroundColor: '#7a7a7a',
        '$checked$checked + &': {
          // Controls checked color for the track
          opacity: 0.7,
          backgroundColor: '#ff6600',
        },
      },
    },
  },
});
