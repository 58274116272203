import config from '../config';

// action type constants
export const SETUP_MAP_SERVICES = 'SETUP_MAP_SERVICES';
export const SET_MAP_DIV = 'SET_MAP_DIV';
export const SET_CURRENT_BOUNDS = 'SET_CURRENT_BOUNDS';

// actionCreators
export const setupMapServices = () => ({ type: SETUP_MAP_SERVICES });
export const setMapDiv = (mapDiv) => ({ type: SET_MAP_DIV, mapDiv });
export const setCurrentBounds = (currentBounds) => ({ type: SET_CURRENT_BOUNDS, currentBounds });

const initialState = {
  mapDiv: '',
  currentBounds: { ...config.fullExtent },
};

// reducer
export default (state = initialState, { type, mapDiv, currentBounds }) => {
  switch (type) {
    case SET_MAP_DIV:
      return { ...state, mapDiv };
    case SET_CURRENT_BOUNDS:
      return { ...state, currentBounds };
    default:
      return state;
  }
};
