import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Slider from '@material-ui/core/Slider';
import { useDispatch, useSelector } from 'react-redux';
import { setDistance, submitSearch } from '../../reducers/queryForm';

const useStyles = makeStyles({
  root: {
    width: '100%',
    paddingTop: 30,
    paddingLeft: 30,
    paddingRight: 30,
  },
  label: {
    color: '#0e78be',
    fontWeight: 'bold',
  },
});

const marks = [
  {
    value: 0,
    label: '5',
  },
  {
    value: 1,
    label: '10',
  },
  {
    value: 2,
    label: '25',
  },
  {
    value: 3,
    label: '50',
  },
  {
    value: 4,
    label: '100',
  },
  {
    value: 5,
    label: '250',
  },
  {
    value: 6,
    label: '500',
  },
];

function valuetext(value) {
  return `${marks[value].label} miles`;
}

export default function DiscreteSlider() {
  const classes = useStyles();

  const dispatch = useDispatch();
  const distance = useSelector((state) => state.queryForm.distance);

  const { value } = marks.find((m) => m.label === distance.toString());

  const onChange = (e, value) => {
    dispatch(setDistance(parseInt(marks[value].label, 10)));
    dispatch(submitSearch());
  };

  return (
    <div className={classes.root}>
      <Typography id="distance-label" className={classes.label}>
        Distance in miles to search
      </Typography>
      <Slider
        value={value}
        getAriaValueText={valuetext}
        aria-labelledby="distance-label"
        step={1}
        marks={marks}
        min={0}
        max={6}
        onChange={onChange}
      />
    </div>
  );
}
