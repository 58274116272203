import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import { useDispatch, useSelector } from 'react-redux';
import { setContext, submitSearch } from '../../reducers/queryForm';

const useStyles = makeStyles({
  root: {
    width: '100%',
    paddingTop: 20,
    paddingLeft: 30,
    paddingRight: 30,
  },
  label: {
    color: '#0e78be',
    fontWeight: 'bold',
  },
  select: {
    width: '100%',
  },
  icon: {
    fontFamily: 'Material Icons',
    fontSize: 24,
    verticalAlign: 'middle',
    marginRight: 5,
    color: '#404040',
  },
});

export default function ContextChooser() {
  const classes = useStyles();

  const dispatch = useDispatch();
  const context = useSelector((state) => state.queryForm.context);

  const onChange = (e) => {
    dispatch(setContext(e.target.value));
    dispatch(submitSearch());
  };

  return (
    <div className={classes.root}>
      <Typography id="context-label" className={classes.label}>
        Type of visit
      </Typography>
      <Select
        className={classes.select}
        id="context-select"
        aria-labelledby="context-label"
        value={context}
        onChange={onChange}
      >
        <MenuItem value="Telehealth">
          <span className={classes.icon}>&#xf080;</span> Telehealth
        </MenuItem>
        <MenuItem value="In-person">
          <span className={classes.icon}>&#xe939;</span> In-person
        </MenuItem>
        <MenuItem value="Both">
          <span className={classes.icon}>&#xf051;</span> Both
        </MenuItem>
      </Select>
    </div>
  );
}
