import { put, takeLatest } from 'redux-saga/effects';
import { setTaps, LOAD_TAPS } from '../reducers/taps';
import { updateTaps, showTapsOnMap } from '../utils/map-google/mapUtils';

let loaded = false;

function* fetchTaps() {
  if (loaded) {
    return showTapsOnMap();
  }
  try {
    const data = yield fetch('/tap').then((resp) => resp.json());
    yield put(setTaps(data));
    updateTaps(data);
    loaded = true;
  } catch (e) {
    // TODO: alert user
    // eslint-disable-next-line no-console
    console.log('ERROR fetching taps data', e);
  }
}

export default function* watch() {
  yield takeLatest(LOAD_TAPS, fetchTaps);
}
