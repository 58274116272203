import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

import Button from '@material-ui/core/Button';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
// import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';


const useStyles = makeStyles({
  root: {
  },
  content: {
    maxWidth: 500,
  },
  name: {
    fontWeight: 'bold',
  },
  nameLink: {
    textDecoration: 'none',
    color: '#2f788f',
    fontWeight: 'bold',
  },
  phoneLink: {
    display: 'inline-block',
    textDecoration: 'none',
    color: '#2f788f',
    fontSize: 16,
    paddingTop: 4,
    paddingBottom: 4,
  },
  services: {
    paddingLeft: 30,
    textIndent: -30,
  },
  label: {
    fontWeight: 'bold',
  },
});

const DetailsModal = ({ data: facility, handleClose }) => {
  const classes = useStyles();

  let name = <span className={classes.name}>{ facility.account_name }</span>;
  let translationAvailable = '';
  let inPersonServices = '';
  let telehealthServices = '';

  if (facility.website) {
    name = <a href={facility.website} className={classes.nameLink} rel="noreferrer" target="_blank">{ facility.account_name }</a>;
  }

  const phoneUri = `tel:${facility.phone}`;
  const phone = <><br /><a href={phoneUri} className={classes.phoneLink}>{ facility.phone }</a></>;

  if (facility.translation_services) {
    translationAvailable = <p><span className={classes.label}>Translation Available:</span> { facility.translation_services }</p>;
  }

  if (facility.in_person_services.length) {
    const inPerson = facility.in_person_services.join(', ');
    inPersonServices = <p className={classes.services}><span className={classes.label}>In-Person Services:</span> { inPerson }</p>;
  }

  if (facility.telehealth_services.length) {
    const telehealth = facility.telehealth_services.join(', ');
    telehealthServices = <p className={classes.services}><span className={classes.label}>Telehealth Services:</span> { telehealth }</p>;
  }

  let location;

  if (facility.telehealth_only) {
    location = <>Serving all of { facility.telehealth_states.sort().join(', ') }</>;
  } else {
    location = <>{ facility.address }<br />{ facility.city }, { facility.state }</>;
  }

  return (
    <>
      <DialogTitle id="responsive-dialog-title">More information</DialogTitle>
      <DialogContent className={classes.content}>
        { name }<br />
        { facility.facility_type }
        { phone }<br />
        { location }<br />
        { translationAvailable }
        { inPersonServices }
        { telehealthServices }
      </DialogContent>
      <DialogActions>
        <Button autoFocus onClick={handleClose} color="primary">
          Close
        </Button>
      </DialogActions>
    </>
  );
};

export default DetailsModal;
