// import { google } from '@google-cloud/datastore/build/protos/protos';
import { Loader } from '@googlemaps/loader';
import config from '../../config';
import store from '../../store';

// import loading from '../loading';
import createMapOptions from './createMapOptions';
import tapIcon from '../../assets/wifi_tethering_orange.png';
import bothIcon from '../../assets/remember_me.png';
import inPersonIcon from '../../assets/supervised_user_circle.png';
import telehealthIcon from '../../assets/video_camera_front.png';
import { openModal } from '../../reducers/modal';
import { setCurrentBounds } from '../../reducers/mapRefs';
import { modalTypes } from '../../components/Modal/Modal';

let map;
const facilityMarkers = [];
const tapsMarkers = [];
let searchCircle;
let userLocationMarker;

const { google: { mapOptions, GOOGLE_MAPS_API_KEY } } = config;

const zoomTaps = () => {
  if (!tapsMarkers.length) {
    return;
  }

  const { google } = window;

  const zoom = map.getZoom();
  // eslint-disable-next-line
  const size = zoom <= 6 ? 16 : (zoom <= 9 ? 24 : 32);
  const anchor = size * 0.5;

  const icon = tapsMarkers[0].getIcon();
  icon.scaledSize = new google.maps.Size(size, size);
  icon.anchor = new google.maps.Point(anchor, anchor);

  tapsMarkers.forEach((m) => {
    m.setIcon(icon);
  });
};

export const createMap = async () => {
  // const loadingMsg = 'Loading Google Maps API';
  // loading.start(loadingMsg);
  // set up Google Maps API loader
  const loader = new Loader({ apiKey: GOOGLE_MAPS_API_KEY, libraries: ['places'] });
  // trigger loading of Google Maps API
  await loader.load();
  const { google } = window;

  // TODO: get zoom, lat, lng from URL and pass them to createMapOptions

  // set map options
  const mapOptions = createMapOptions();
  map = new google.maps.Map(document.getElementById('map'), mapOptions);

  const tileMapType = new google.maps.ImageMapType({
    getTileUrl: (coord, zoom) => `/tiles/${zoom}/${coord.x}/${coord.y}.png`,
    tileSize: new google.maps.Size(256, 256),
    minZoom: 0,
    maxZoom: 6,
  });

  map.overlayMapTypes.insertAt(0, tileMapType);

  map.fitBounds({ ...config.fullExtent });

  map.addListener('zoom_changed', zoomTaps);

  // loading.end(loadingMsg);
  return map;
};

const drawUserLocation = (userLocation, radius) => {
  if (userLocationMarker) {
    searchCircle.setMap(null);
    userLocationMarker.setMap(null);
  }

  searchCircle = new window.google.maps.Circle({
    center: userLocation,
    map,
    radius,
    fillColor: 'red',
    fillOpacity: 0.05,
    strokeColor: 'red',
    strokeOpacity: 0.2,
    title: 'Search Area',
  });

  userLocationMarker = new window.google.maps.Marker({
    position: userLocation,
    map,
    title: 'My Location',
    zIndex: 2,
  });

  return searchCircle.getBounds();
};

const drawFacilities = (newFacilities) => {
  const { google } = window;

  facilityMarkers.forEach((m) => m.setMap(null));
  facilityMarkers.length = 0;

  newFacilities.forEach((f) => {
    if (f.location) {
      const latLng = {
        lat: f.location.coordinates[1],
        lng: f.location.coordinates[0],
      };

      let icon = bothIcon;

      if (!f.in_person_services.length) {
        icon = telehealthIcon;
      }

      if (!f.telehealth_services.length) {
        icon = inPersonIcon;
      }

      const m = new google.maps.Marker({
        position: latLng,
        map,
        title: f.account_name,
        icon: {
          url: icon,
          size: new google.maps.Size(64, 64),
          scaledSize: new google.maps.Size(32, 32),
          anchor: new google.maps.Point(16, 16),
        },
        zIndex: 1,
      });

      m.addListener('click', () => {
        store.dispatch(openModal({ type: modalTypes.DETAILS_MODAL, data: f }));
      });

      facilityMarkers.push(m);
    }
  });
};

export const updateMap = (userLocation, radius, newFacilities) => {
  const bounds = drawUserLocation(userLocation, radius);
  drawFacilities(newFacilities);
  store.dispatch(setCurrentBounds(bounds));
};

export const updateTaps = (taps) => {
  const { google } = window;

  tapsMarkers.forEach((m) => m.setMap(null));
  tapsMarkers.length = 0;

  const icon = {
    url: tapIcon,
    size: new google.maps.Size(64, 64),
    scaledSize: new google.maps.Size(16, 16),
    anchor: new google.maps.Point(8, 8),
  };

  taps.forEach((t) => {
    if (t.location) {
      const latLng = {
        lat: t.location.coordinates[1],
        lng: t.location.coordinates[0],
      };

      const m = new google.maps.Marker({
        position: latLng,
        map,
        title: t.account_name,
        icon,
        zIndex: 1,
      });

      m.addListener('click', () => {
        store.dispatch(openModal({ type: modalTypes.TAPS_MODAL, data: t }));
      });

      tapsMarkers.push(m);
    }
  });
};

export const removeTapsFromMap = () => {
  tapsMarkers.forEach((m) => m.setMap(null));
};

export const showTapsOnMap = () => {
  tapsMarkers.forEach((m) => m.setMap(map));
};

export const resetMapView = () => map.setOptions(mapOptions);

export const getMap = () => map;
