// action type constants
export const SET_LOCATION = 'SET_LOCATION';
export const SET_DISTANCE = 'SET_DISTANCE';
export const SET_CONTEXT = 'SET_CONTEXT';
export const SET_SELECTED_SERVICES = 'SET_SELECTED_SERVICES';
export const SET_ALL_SERVICES = 'SET_ALL_SERVICES';
export const LOAD_SERVICES = 'LOAD_SERVICES';
export const SUBMIT_SEARCH = 'SUBMIT_SEARCH';

// actionCreators
export const setLocation = (payload) => ({ type: SET_LOCATION, payload });
export const setDistance = (payload) => ({ type: SET_DISTANCE, payload });
export const setContext = (payload) => ({ type: SET_CONTEXT, payload });
export const setSelectedServices = (payload) => ({ type: SET_SELECTED_SERVICES, payload });
export const setAllServices = (payload) => ({ type: SET_ALL_SERVICES, payload });
export const loadServices = () => ({ type: LOAD_SERVICES });
export const submitSearch = () => ({ type: SUBMIT_SEARCH });

const initialState = {
  location: {
    lat: Number.NaN,
    lng: Number.NaN,
    state: null,
  },
  distance: 5,
  context: 'Telehealth',
  selectedServices: 'Family Medicine',
  allServices: [],
};

// reducer
export default (state = initialState, { type, payload }) => {
  switch (type) {
    case SET_LOCATION:
      return { ...state, location: payload };
    case SET_DISTANCE:
      return { ...state, distance: payload };
    case SET_CONTEXT:
      return { ...state, context: payload };
    case SET_SELECTED_SERVICES:
      return { ...state, selectedServices: payload };
    case SET_ALL_SERVICES:
      return { ...state, allServices: payload };
    default:
      return state;
  }
};
