import React from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { useDispatch, useSelector } from 'react-redux';
import Button from '@material-ui/core/Button';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { setSidebar } from '../../reducers/sidebar';

const useStyles = makeStyles(() => ({
  root: {
    width: '100%',
    paddingTop: 30,
    paddingLeft: 30,
    paddingRight: 30,
    fontSize: '1rem',
    fontWeight: 'bold',
  },
  button: {
    marginLeft: 20,
  },
}));

export default function FacilityCount() {
  const theme = useTheme();
  const onSmallScreen = useMediaQuery(theme.breakpoints.down('xs'));

  const classes = useStyles();
  const facilities = useSelector((state) => state.facility);

  let message = 'No facilities';
  const hasFacilities = facilities && facilities.length;

  if (hasFacilities) {
    message = facilities.length === 1 ? '1 facility found' : `${facilities.length} facilities found, `;
    const mapped = facilities.filter((f) => f.location).length;
    message += mapped === 1 ? '1 on map' : `${mapped} on map`;
  }

  const dispatch = useDispatch();

  const hideSidebar = () => {
    dispatch(setSidebar(false));
  };

  let showButton;

  if (onSmallScreen) {
    showButton = (
      <Button className={classes.button} color="primary" disabled={!hasFacilities} onClick={hideSidebar}>
        Show &gt;
      </Button>
    );
  }

  return (
    <div className={classes.root}>
      <span>{message}</span>
      {showButton}
    </div>
  );
}
