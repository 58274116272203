import React from 'react';
import { connect } from 'react-redux';
import clsx from 'clsx';

import { makeStyles, useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';

import Paper from '@material-ui/core/Paper';
import SidebarQuery from './SidebarQuery/SidebarQuery';

import config from '../config';

const { headerHeight, sidebarWidth } = config;

const useStyles = makeStyles((theme) => ({
  drawerPaper: {
    position: ({ onSmallScreen }) => (onSmallScreen ? 'absolute' : 'relative'),
    whiteSpace: 'nowrap',
    width: sidebarWidth,
    opacity: 1,
    transition: theme.transitions.create(['width', 'opacity'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    background: '#fafafa',
    borderRadius: 0,
    height: `calc(100vh - ${headerHeight}px)`,
    overflowY: 'scroll',
    zIndex: 2,
  },
  drawerPaperClose: {
    overflowX: 'hidden',
    transition: theme.transitions.create(['width', 'opacity'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    width: 0,
    opacity: 0,
  },
}));

const Sidebar = ({ sidebar }) => {
  const theme = useTheme();
  const onSmallScreen = useMediaQuery(theme.breakpoints.down('xs'));

  const classes = useStyles({ sidebar, onSmallScreen });

  return (
    <Paper
      className={clsx(classes.drawerPaper, 'no-print', !sidebar && classes.drawerPaperClose)}
    >

      <SidebarQuery />

    </Paper>
  );
};

const mapStateToProps = ({ sidebar }) => ({ sidebar });

export default connect(mapStateToProps)(Sidebar);
