import React, { useEffect } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import { setCurrentBounds } from '../../reducers/mapRefs';
import { getMap } from '../../utils/map-google/mapUtils';
import config from '../../config';

const { headerHeight, sidebarWidth } = config;

const useStyles = makeStyles({
  mapWrapper: {
    width: ({ currentSidebarWidth }) => `calc(100% - ${currentSidebarWidth}px)`,
    height: ({ height }) => height || `calc(100vh - ${headerHeight}px)`,
  },
});

const scaleBounds = (bounds, scale) => {
  const sw = bounds.getSouthWest();
  const ne = bounds.getNorthEast();
  const cLat = (ne.lat + sw.lat) * 0.5;
  const cLng = (ne.lng + sw.lng) * 0.5;
  const dLat = (ne.lat - sw.lat) * 0.5 * scale;
  const dLng = (ne.lng - sw.lng) * 0.5 * scale;
  return new window.google.maps.LatLngBounds([{ lat: cLat - dLat, lng: cLng - dLng }, { lat: cLat + dLat, lng: cLng + dLng }]);
};

const MapPanel = ({ mapDiv, height }) => {
  const sidebarOpen = useSelector((state) => state.sidebar);
  const currentSidebarWidth = sidebarOpen ? sidebarWidth : 0;
  const classes = useStyles({ height, currentSidebarWidth });
  const dispatch = useDispatch();

  // reset map view when unmounting map
  // useEffect(() => resetMapView, []);

  // init map on first mount, otherwise append mapDiv to component div
  useEffect(() => {
    if (mapDiv) {
      mapDiv.style.display = 'block';
      mapDiv.style.height = '100%';
      document.getElementById('map-wrapper').appendChild(mapDiv);
    }
    return () => {
      const map = getMap();
      const currentBounds = map.getBounds();

      if (currentBounds) {
        dispatch(setCurrentBounds(scaleBounds(currentBounds, 0.999)));
      }

      if (mapDiv?.style?.display) mapDiv.style.display = 'none';
      // cleanup height property from above
      if (mapDiv?.style?.height) mapDiv.style.height = null;
    };
  }, [mapDiv]);

  const currentBounds = useSelector((state) => state.mapRefs.currentBounds);

  useEffect(() => {
    if (currentBounds) {
      const map = getMap();

      if (map) {
        map.fitBounds(currentBounds);
      }
    }
  }, [currentBounds]);

  return (
    <div id="map-wrapper" className={classes.mapWrapper} />
  );
};

const mapStateToProps = ({ mapRefs: { mapDiv } }) => ({ mapDiv });

export default connect(mapStateToProps)(MapPanel);
