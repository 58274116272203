import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { FormControl, FormLabel, RadioGroup, FormControlLabel, Radio } from '@material-ui/core';

const useStyles = makeStyles({
  label: {
    color: 'black',
    paddingTop: 10,
    paddingBottom: 10,
  },
  group: {
    paddingLeft: 30,
  },
});

const UserSurvey = () => {
  const [userType, setUserType] = useState(window.localStorage.getItem('usertype'));

  const handleChange = (event) => {
    const { value } = event.target;
    setUserType(value);
    window.localStorage.setItem('usertype', value);
  };

  const classes = useStyles();

  return (
    <FormControl component="fieldset">
      <FormLabel className={classes.label} component="legend">Please tell us who you are:</FormLabel>
      <RadioGroup className={classes.group} aria-label="User type" name="userType" value={userType} onChange={handleChange}>
        <FormControlLabel value="patient" control={<Radio />} label="A patient" />
        <FormControlLabel value="provider" control={<Radio />} label="A health care provider" />
      </RadioGroup>
    </FormControl>
  );
};

export default UserSurvey;
