// action type constants
export const FETCH_FACILITIES = 'FETCH_FACILITIES';
export const SET_FACILITIES = 'SET_FACILITIES';

// actionCreators
export const fetchFacilities = () => ({ type: FETCH_FACILITIES });
export const setFacilities = (data) => ({ type: SET_FACILITIES, data });

const initialState = [];

// reducer
export default (state = initialState, { type, data }) => {
  switch (type) {
    case SET_FACILITIES:
      return data;
    default:
      return state;
  }
};
