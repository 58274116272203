import React from 'react';
import clsx from 'clsx';
import { Switch, Route, Redirect, Link, useRouteMatch } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import { useSelector } from 'react-redux';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import Button from '@material-ui/core/Button';
import MapPanel from './MapPanel/GoogleMap';
import ListPanel from './ListPanel/ListPanel';
import TapControl from './TapControl';

const useStyles = makeStyles(() => ({
  align: {
    position: 'relative',
    zIndex: 1,
  },
  group: {
    position: 'absolute',
    left: 15,
    top: 15,
    backgroundColor: 'white',
  },
  routeSelected: {
    backgroundColor: 'white',
  },
  routeNormal: {
    backgroundColor: '#E8EDEF',
    color: 'darkgray',
  },
  alert: {
    position: 'absolute',
    left: 160,
    top: 22,
    width: 298,
    paddingLeft: 6,
    fontWeight: 'bold',
    backgroundColor: 'rgba(255, 255, 255, 0.6)',
  },
  tap: {
    position: 'absolute',
    top: '68px',
  },
}));

const MainContentRouter = () => {
  const classes = useStyles();
  const onMap = !!useRouteMatch('/map');

  let moreAlert = '';
  const facilities = useSelector((state) => state.facility);

  if (onMap && facilities.find((f) => f.telehealth_only)) {
    moreAlert = <div className={clsx(classes.alert)}>See the List for additional telehealth options</div>;
  }

  return (
    <>
      <div className={clsx(classes.align, 'no-print')}>
        <ButtonGroup className={classes.group}>
          <Button className={onMap ? classes.routeSelected : classes.routeNormal} component={Link} to="/map">Map</Button>
          <Button className={onMap ? classes.routeNormal : classes.routeSelected} component={Link} to="/list">List</Button>
        </ButtonGroup>
        { moreAlert }
        {onMap ? <TapControl className={classes.tap} /> : false}
      </div>
      <Switch>
        <Route exact path="/map"><MapPanel /></Route>
        <Route exact path="/list"><ListPanel /></Route>
        <Redirect to="/map" />
      </Switch>
    </>
  );
};

export default MainContentRouter;
