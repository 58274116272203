import { put } from 'redux-saga/effects';

import { SETUP_MAP_SERVICES, setMapDiv } from '../reducers/mapRefs';

import { takeOnce } from '../utils/redux';
import { createMap } from '../utils/map-google/mapUtils';

function* setupMapServices() {
  const mapDiv = document.getElementById('map');
  const map = yield createMap(mapDiv);

  // if in development environment, make map available in console
  if (process.env.REACT_APP_DEV) window.gmap = map;

  // set map reference in redux store
  yield put(setMapDiv(mapDiv));
}

export default function* watch() {
  yield takeOnce(SETUP_MAP_SERVICES, setupMapServices);
}
