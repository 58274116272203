export default {
  headerHeight: 64,
  sidebarWidth: 300,
  drawerHeight: '50vh',
  basemaps: {
    carto_grey: 'https://{s}.basemaps.cartocdn.com/light_all/{z}/{x}/{y}.png',
    usgs_topo: 'https://basemap.nationalmap.gov/arcgis/rest/services/USGSTopo/MapServer/tile/{z}/{y}/{x}',
  },
  leaflet: {
    mapOptions: {
      center: [38.19398442610292, -107.58391723036766],
      zoom: 4,
    },
  },
  fullExtent: {
    west: -125,
    north: 50,
    east: -66,
    south: 24,
  },
  google: {
    mapOptions: {
      center: { lat: -43, lng: -114 },
      zoom: 6,
      gestureHandling: 'greedy',
    },
    GOOGLE_MAPS_API_KEY: 'AIzaSyCWccAIxkAG39UgjjyyW1dtFP3c1CXaSQg',
  },
};
