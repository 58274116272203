import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import { useDispatch, useSelector } from 'react-redux';
import { setSelectedServices, loadServices, submitSearch } from '../../reducers/queryForm';

const useStyles = makeStyles({
  root: {
    width: '100%',
    paddingTop: 30,
    paddingLeft: 30,
    paddingRight: 30,
  },
  label: {
    color: '#0e78be',
    fontWeight: 'bold',
  },
  select: {
    width: '100%',
  },
});

export default function ServiceChooser() {
  const classes = useStyles();

  const dispatch = useDispatch();
  const selectedServices = useSelector((state) => state.queryForm.selectedServices);
  const allServices = useSelector((state) => state.queryForm.allServices);

  const onChange = (e) => {
    dispatch(setSelectedServices(e.target.value));
    dispatch(submitSearch());
  };

  useEffect(() => {
    dispatch(loadServices());
  }, []);

  return (
    <div className={classes.root}>
      <Typography id="service-label" className={classes.label}>
        Service
      </Typography>
      <Select
        className={classes.select}
        id="service-select"
        aria-labelledby="service-label"
        value={selectedServices}
        onChange={onChange}
      >
        {
          allServices.map(({ name }) => <MenuItem key={name} value={name}>{name}</MenuItem>)
        }
      </Select>
    </div>
  );
}
