import React from 'react';
import clsx from 'clsx';
import { useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';

const useStyles = makeStyles({
  root: {},
  content: {
    maxWidth: 500,
  },
  name: {
    fontWeight: 'bold',
  },
  nameLink: {
    textDecoration: 'none',
    color: '#2f788f',
    fontWeight: 'bold',
  },
  phoneLink: {
    display: 'inline-block',
    textDecoration: 'none',
    color: '#2f788f',
    fontSize: 16,
    paddingTop: 4,
    paddingBottom: 4,
  },
  driveIcon: {
    position: 'absolute',
    right: '70px',
    fontFamily: 'Material Icons',
    fontSize: 24,
    width: 30,
    height: 30,
    minWidth: 30,
    minHeight: 30,
    color: '#404040',
  },
});

const TapsModal = ({ data: taps, handleClose }) => {
  const classes = useStyles();
  const queryForm = useSelector((state) => state.queryForm);

  let name = <span className={classes.name}>{taps.account_name}</span>;
  const phoneUri = `tel:${taps.phone}`;
  const phone = (
    <>
      <br />
      <a href={phoneUri} className={classes.phoneLink}>
        {taps.phone}
      </a>
    </>
  );
  const { organization_type } = taps;

  const location = (
    <>
      {taps.address} <br />
      {taps.city}, {taps.state}
    </>
  );

  if (taps.website) {
    name = (
      <a
        href={taps.website}
        className={classes.nameLink}
        rel="noreferrer"
        target="_blank"
      >
        {taps.account_name}
      </a>
    );
  }

  const getDirections = () => {
    const coords = taps.location.coordinates;
    let origin = '';

    if (queryForm.location.lat) {
      origin = `&origin=${queryForm.location.lat},${queryForm.location.lng}`;
    }

    window.open(
      `https://www.google.com/maps/dir/?api=1${origin}&destination=${coords[1]},${coords[0]}&travelmode=driving`,
      '_blank',
    );
  };

  return (
    <>
      <DialogTitle id="responsive-dialog-title">
        Telehealth Access Point
      </DialogTitle>
      <DialogContent className={classes.content}>
        {name}
        <br />
        {organization_type}
        {phone}
        <br />
        {location}
        <br />
      </DialogContent>
      <DialogActions>
        <Button autoFocus onClick={handleClose} color="primary">
          Close
        </Button>
        <Button
          id="directions"
          title="Directions"
          className={clsx(classes.driveIcon, 'no-print')}
          onClick={getDirections}
        >
          &#xeff7;
        </Button>
      </DialogActions>
    </>
  );
};

export default TapsModal;
