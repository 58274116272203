import { bindActionCreators } from 'redux';
import { call, take } from 'redux-saga/effects';

// function to replace streamline normal bindActionCreators workflow for mapDispatchToProps
export const createDispatchBindings = (actionCreators) => (dispatch) => bindActionCreators(actionCreators, dispatch);

// function to ensure a saga is only run once
export function* takeOnce(pattern, saga) {
  let once = false;
  while (!once) {
    const action = yield take(pattern);
    once = true;
    yield call(saga, action);
  }
}
