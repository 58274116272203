// action type constants
export const SHOW_SNACKBAR = 'SHOW_SNACKBAR';
export const HIDE_SNACKBAR = 'HIDE_SNACKBAR';

// actionCreators
export const showSnackbar = (message) => ({ type: SHOW_SNACKBAR, message });
export const hideSnackbar = () => ({ type: HIDE_SNACKBAR });

const initialState = {
  open: false,
  message: '',
  severity: 'success',
};

// reducer
export default (state = initialState, { type, message }) => {
  switch (type) {
    case SHOW_SNACKBAR:
      return { open: true, message };
    case HIDE_SNACKBAR:
      return initialState;
    default:
      return state;
  }
};
