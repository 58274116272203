import React, { useEffect } from 'react';
import clsx from 'clsx';
import { connect, useDispatch } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';

import Modal, { modalTypes } from './components/Modal/Modal';
import AppHeader from './components/AppHeader';
import Sidebar from './components/Sidebar';
import MainContentRouter from './components/MainContentRouter';
import SnackbarMessage from './components/SnackbarMessage';

import { setupMapServices } from './reducers/mapRefs';

import { createDispatchBindings } from './utils/redux';
import { setSidebar } from './reducers/sidebar';

import { openModal } from './reducers/modal';

import loading from './utils/loading';
import config from './config';

const { headerHeight } = config;

const useStyles = makeStyles({
  appBarSpacer: {
    height: 64,
  },
  root: {
    display: 'flex',
    minHeight: '100vh',
    flexDirection: 'column',
  },
  main: {
    width: '100vw',
    display: 'flex',
    height: `calc(100% - ${headerHeight}px)`,
  },
});

const defaultUserType = 'patient';

const reportUserType = (userType) => {
  window.gtag('event', 'user_type_dimension', { userType });
};

const App = ({ setupMapServices }) => {
  // setup map and load
  useEffect(() => {
    loading.end('Loading Application...');
    setupMapServices();
  }, [setupMapServices]);

  const dispatch = useDispatch();

  // show disclaimer modal based on user type in local storage

  useEffect(() => {
    let userType;
    let storageAllowed = true;

    try {
      userType = window.localStorage.getItem('usertype');
    } catch {
      storageAllowed = false;
    }

    if (!storageAllowed) {
      reportUserType(defaultUserType);
      dispatch(openModal({ type: modalTypes.DISCLAIMER_MODAL }));
    } else if (!userType) {
      window.localStorage.setItem('usertype', defaultUserType);
      dispatch(openModal({ type: modalTypes.DISCLAIMER_MODAL, data: { showUserSurvey: true } }));
    } else {
      reportUserType(userType);
    }
  }, []);

  const classes = useStyles();

  return (
    <>
      <SnackbarMessage />
      <div className={classes.root}>
        <AppHeader />
        <Modal />
        <div className={clsx(classes.appBarSpacer, 'no-print')} />
        <main className={classes.main}>
          <Sidebar />
          <MainContentRouter />
        </main>
      </div>
    </>
  );
};


const mapDispatchToProps = createDispatchBindings({ setupMapServices, setSidebar });

export default connect(null, mapDispatchToProps)(App);
