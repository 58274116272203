import { combineReducers } from 'redux';

import mapRefs from './mapRefs';
import sidebar from './sidebar';
import modal from './modal';
import snackbar from './snackbar';
import queryForm from './queryForm';
import facility from './facility';
import taps from './taps';

const reducers = combineReducers({
  sidebar,
  mapRefs,
  modal,
  snackbar,
  queryForm,
  facility,
  taps,
});

export default reducers;
