
// action types
export const OPEN_MODAL = 'OPEN_MODAL';
export const CLOSE_MODAL = 'CLOSE_MODAL';

// reducer with initial state
const initialState = {
  open: false,
  type: '',
  size: 'sm',
  fullScreen: false,
  data: null,
};

// actionCreators
export const openModal = ({ type, data, options = {} }) => ({ type: OPEN_MODAL, payload: { type, data, options } });
export const closeModal = () => ({ type: CLOSE_MODAL });

// selectors

// reducer
export default (state = initialState, { type, payload }) => {
  switch (type) {
    case OPEN_MODAL: {
      const { type, data, options } = payload;
      return { ...state, ...options, open: true, type, data };
    }
    case CLOSE_MODAL:
      return initialState;
    default:
      return state;
  }
};
