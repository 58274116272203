// action type constants
export const SET_TAPS = 'SET_TAPS';
export const LOAD_TAPS = 'LOAD_TAPS';
export const SET_TAPS_ON = 'SET_TAPS_ON';

// actionCreators
export const setTaps = (data) => ({ type: SET_TAPS, data });
export const loadTaps = () => ({ type: LOAD_TAPS });
export const setTapsOn = (tapsOn) => ({ type: SET_TAPS_ON, tapsOn });
const initialState = { tapsOn: false, data: [] };

// Selectors
export const getTapSwitchState = (state) => state.taps.tapsOn;

// reducer
export default (state = initialState, { type, data, tapsOn }) => {
  switch (type) {
    case SET_TAPS:
      return { ...state, data };
    case SET_TAPS_ON:
      return { ...state, tapsOn };
    default:
      return state;
  }
};

