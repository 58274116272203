import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import EmergencyNotice from './EmergencyNotice';
import UserSurvey from './UserSurvey';

const useStyles = makeStyles({
  survey: {
    paddingLeft: 30,
  },
  nameLink: {
    textDecoration: 'none',
    color: '#2f788f',
    fontWeight: 'bold',
  },
  close: {
    marginRight: 15,
  },
  iconDef: {
    verticalAlign: 'middle',
    marginLeft: 60,
    textIndent: -30,
  },
  icon: {
    fontFamily: 'Material Icons',
    fontSize: 24,
    verticalAlign: 'middle',
  },
});


const DisclaimerModal = ({ data, handleClose }) => {
  const classes = useStyles();
  const showSurvey = data && data.showUserSurvey;

  const submitSurvey = () => {
    if (showSurvey) {
      const userType = window.localStorage.getItem('usertype');
      window.gtag('event', 'user_type_dimension', { userType });
    }

    handleClose();
  };

  const userSurvey = showSurvey ? <UserSurvey className={classes.survey} /> : undefined;

  return (
    <>
      <DialogTitle id="responsive-dialog-title">Disclaimer</DialogTitle>
      <DialogContent>
        <EmergencyNotice />
        {userSurvey}
        <p>Information on this map is to identify health care facilities that offer health care
          services via telehealth and in-person.  If you are a patient and you want to contact a provider who offers
          telehealth services in another state, you are encouraged to check with that provider to see if
          they are licensed in your state and also check with your insurance to make sure that the visit
          will be covered.
        </p>
        <p>The data is updated through a collaboration between the <a href="https://nrtrc.org" className={classes.nameLink} rel="noreferrer" target="_blank">NRTRC</a>,&nbsp;
          <a href="https://southwesttrc.org/" className={classes.nameLink} rel="noreferrer" target="_blank">SWTRC</a>,&nbsp;
          <a href="https://www.sanborn.com" className={classes.nameLink} rel="noreferrer" target="_blank">Sanborn</a>, and&nbsp;
          <a href="https://wimtracking.com" className={classes.nameLink} rel="noreferrer" target="_blank">WIM Tracking, LLC</a>&nbsp;(the
          “Collaboration”).
        </p>
        <p>The Collaboration has attempted to provide up-to-date and accurate information.
          Nevertheless, the information contained on this map is provided as-is and may include errors or
          become out ofdate.  All users are encouraged to verify the information they obtain by contacting the
          provider directly.  Each of the members of the Collaboration expressly disclaims any guarantee of
          accuracy and users are hereby advised that, by using this tool, you agree to hold the members of
          the Collaboration harmless from any claim or liability arising out of your use of this tool.  The
          members of the collaboration are not affiliated with any provider identified using this tool and
          inclusion of provider information in this tool is not intended as an endorsement of or
          representation with respect to the care that might be provided by any such provider.
        </p>
        <p>This project is supported by the Health Resources and Services Administration (HRSA) of the U.S.
          Department of Health and Human Services (HHS) under G22RH31114, GA5RH37461-01-00, and 6 U1UTH42527-03-01 Telehealth
          Resource Center’s Grant Program. This information, content and conclusions are those of the author
          and should not be construed as the official position or policy of, nor should any endorsements be
          inferred by HRSA, HHS or the U.S. Government.
        </p>
      </DialogContent>
      <DialogActions>
        <Button className={classes.close} onClick={submitSurvey} color="primary">
          I Understand
        </Button>
      </DialogActions>
    </>
  );
};

export default DisclaimerModal;
