import React from 'react';
import clsx from 'clsx';
import { connect } from 'react-redux';

import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';

import { setSidebar } from '../reducers/sidebar';

import { createDispatchBindings } from '../utils/redux';

import logo from '../assets/nrtrc_logo.png';
import config from '../config';

const { headerHeight } = config;

const useStyles = makeStyles((theme) => ({
  appBar: {
    backgroundColor: 'rgba(221,227,229,.5) !important',
    padding: 0,
    zIndex: 10,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  logo: {
    height: 56,
  },
  logoTitleWrapper: {
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center',
  },
  title: {
    display: 'flex',
    flexWrap: 'wrap',
    color: '#2f788f',
    fontSize: 22,
    marginLeft: 15,
    lineHeight: 1.1,
  },
  toolbar: {
    display: 'flex',
    height: `${headerHeight}px !important`,
  },
  menuButtonWrapper: {
    width: 56,
    color: 'black',
  },
  menuButton: {
    marginRight: 36,
  },
}));

const AppHeader = ({ sidebar, setSidebar }) => {
  const classes = useStyles();
  const handleHamburgerClick = () => setSidebar(!sidebar);

  return (
    <AppBar
      position="absolute"
      className={clsx(classes.appBar, 'no-print')}
    >
      <Toolbar className={classes.toolbar}>
        <div className={classes.menuButtonWrapper}>
          <IconButton
            edge="start"
            color="inherit"
            aria-label="open drawer"
            onClick={handleHamburgerClick}
            className={classes.menuButton}
          >
            <MenuIcon id="sidebar-toggle" />
          </IconButton>
        </div>

        <div className={classes.logoTitleWrapper}>
          <a href="https://nrtrc.org" title="Go to the NRTRC website" className={classes.logo} rel="noreferrer" target="_blank">
            <img className={classes.logo} src={logo} alt="NRTRC Logo" />
          </a>
        </div>

        <div className={classes.title}>Find Telehealth</div>
      </Toolbar>
    </AppBar>
  );
};

const mapStateToProps = ({ sidebar }) => ({ sidebar });

const mapDispatchToProps = createDispatchBindings({ setSidebar });

export default connect(mapStateToProps, mapDispatchToProps)(AppHeader);
