import { fork, all } from 'redux-saga/effects';

import mapSetup from './mapSetup-google';
import fetchFacilities from './fetchFacilities';
import fetchServices from './fetchServices';
import fetchTaps from './fetchTaps';
import removeTaps from './removeTaps';

const sagas = [
  mapSetup,
  fetchFacilities,
  fetchServices,
  fetchTaps,
  removeTaps,
];

function* globalSagas() {
  const globalSagasForks = sagas.map((saga) => fork(saga));

  yield all([...globalSagasForks]);
}

export default globalSagas;
